import { CreativeV2TemplateEnriched } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { TemplateTypeBase } from './base-template';

class TemplateDynamicVideo extends TemplateTypeBase {
    constructor(creative: CreativeV2TemplateEnriched) {
        super(creative);
    }

    getCreativeModel(): string {
        return ``;
    }

    showPlayBar(): boolean {
        return true;
    }

    isMultiFrame(): boolean {
        return false;
    }

    hasOverlay(): boolean {
        return false;
    }
}

export { TemplateDynamicVideo };
