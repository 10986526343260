import { GridItem } from 'helpers/arrangeItemsInGrid';
import { CreativeV2Media, CreativeV2MediaEnriched } from '../components/creative-editor/types/creativeV2.type';
import { CreativeV2Base } from './base-creative.class';

export class CreativeV2MediaType extends CreativeV2Base<CreativeV2MediaEnriched, CreativeV2Media> {
    prepareForArrangement = (): GridItem<CreativeV2MediaEnriched>[] => {
        return [
            {
                item: this.creative,
                height: this.creative.data.height || 0,
                width: this.creative.data.width || 0,
                itemKey: this.creative.id
            }
        ];
    };

    getFormatOverwrites(): undefined {
        return;
    }

    getCreativeModel(): string {
        return `frames.frame`;
    }

    showPlayBar(): boolean {
        return false;
    }

    isMultiFrame(): boolean {
        return false;
    }

    hasOverlay(): boolean {
        return false;
    }
}
