import { CreativeV2TemplateEnriched } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';

/**
 * Base class for creative template types
 */
abstract class TemplateTypeBase {
    protected creative: CreativeV2TemplateEnriched;

    constructor(creative: CreativeV2TemplateEnriched) {
        this.creative = creative;
    }

    abstract getCreativeModel(activeFrame?: string): string;

    abstract showPlayBar(): boolean;

    abstract isMultiFrame(): boolean;

    abstract hasOverlay(): boolean;
}

export { TemplateTypeBase };
